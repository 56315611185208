import React, { createContext, useState } from 'react';

export const PagesContext = createContext({});

const PagesContextProvider = ({ children }) => {
  const [whichID, setWhichID] = useState(0);

  return (
    <PagesContext.Provider value={{ whichID, setWhichID }}>
      {children}
    </PagesContext.Provider>
  );
};

export default PagesContextProvider;
