import App from '../App';
import Services from '../views/Services';
import Home from '../views/Home';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <div>Error</div>,
    children: [
      { path: '/', element: <Home /> },
      {
        path: '/services',
        element: <Services />,
      },
    ],
  },
]);
