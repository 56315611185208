export const services = [
  {
    id: 1,
    titleHome: 'Instalacje wodno - kanalizacyjne',
    titleService: 'Instalacje wodno - kanalizacyjne',
    text: 'W celu zapewnienia bieżącej wody i jej utylizacji konieczne jest wykonanie instalacji wodno - kanalizacyjnej. Zapewniamy prawidłowe funkcjonowanie systemu zachowując niezbędne kryteria wykonawstwa w szerokiej gamie dostępnych systemów na rynku. W zakresie naszych usług znajdują się instalacje wod-kan wewnętrzne, oraz zewnętrzne takie jak przyłącza do budynku.',
  },
  {
    id: 2,
    titleHome: 'Centralne ogrzewanie',
    titleService: 'Instalacja centralnego ogrzewania',
    text: 'Ogrzewanie grzejnikowe, podłogowe, mieszane, a także nagrzewnice   i kurtyny zasilane wodnym czynnikiem grzewczym, są dla nas bardzo dobrze znanym tematem, z którym mamy do czynienia na co dzień. Gwarantujemy prawidłowe wykonanie instalacji zarówno tych najprostszych jak i zautomatyzowanych.',
  },
  {
    id: 3,
    titleHome: 'Instalacje gazowe, p.poż oraz sprężonego powietrza',
    titleService: 'Instalacje gazowe, p.poż. i sprężonego powietrza',
    text: 'Posiadamy niezbędne uprawnienia dozorowe i eksploatacyjne aby zapewnić bezpieczną w użytkowaniu instalację gazu. Wykonujemy również okresowe przeglądy szczelności instalacji gazowych. Oferta obejmuje wykonanie instalacji bezpieczeństwa przeciwpożarowego i sprężonego powietrza w zakładach produkcyjnych z zachowaniem wymaganych standardów BHP obowiązujących na terenie zakładu.',
  },
  {
    id: 4,
    titleHome: 'Montaż kotłów gazowych, na paliwo stałe i pomp ciepła',
    titleService: 'Montaż kotłów gazowych, na paliwo stałe i pomp ciepła',
    text: 'Wybór urządzenia zależny jest od zastosowanych rozwiązań termoizolacyjnych budynku oraz jego przeznaczenia. Na podstawie tych informacji zapewniamy odpowiedni dobór i montaż kotła lub pompy ciepła o mocy grzewczej niezbędnej do zaspokojenia energetycznego obiektu.',
  },
  {
    id: 5,
    titleHome: 'Montaż ceramiki i armatury',
    titleService: 'Montaż ceramiki i armatury',
    text: 'Montujemy wszystkie urządzenia niezbędne do korzystania z sanitariatów i kuchni. Pomagamy w doborze uwzględniając cenę oraz jakość. Do grupy urządzeń zaliczamy wanny, kabiny prysznicowe, umywalki, ubikacje, baterie zewnętrzne i podtynkowe itp.',
  },
  {
    id: 6,
    titleHome: 'Kompleksowe remonty łazienek',
    titleService: 'Kompleksowe remonty łazienek',
    text: 'Od trzech lat do zakresu naszych usług dołączyliśmy kompleksowe remonty i wykończenia łazienek od A do Z. W zakres prac należą usługi takie jak glazurnictwo, układanie płytek, szpachlowanie, malowanie, tapetowanie, zabudowy z katon-gips, wyburzenia oraz wszystkie inne niezbędne prace do wyremontowania łazienki. Zawsze stawiamy na jakość wykonania naszej pracy tak aby efekt finalny cieszył oko zarówno klienta i nas.',
  },
];
