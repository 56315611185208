import phone from '../assets/phone.svg';
import mail from '../assets/mail.svg';
import fb from '../assets/fb.svg';
import insta from '../assets/insta.svg';

import '../styles/Footer.scss';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-blue container">
        <h3 className="font-46-18">GRZEGORZ SZYMASZEK - INSTALACJE</h3>
        <div className="phone">
          <img src={phone} alt="phone" />{' '}
          <a className="font-32-16" href="tel: +48 797 235 265">
            +48 797 235 265
          </a>
        </div>
        <div className="mail">
          <img src={mail} alt="mail" />{' '}
          <a
            className="font-32-16"
            href="mailto:szymaszek.instalacje@gmail.com"
          >
            szymaszek.instalacje@gmail.com
          </a>
        </div>
      </div>
      <div className="footer-white container">
        <p className="font-28-16">Zobacz nasze realizacje:</p>
        <a href="https://www.facebook.com">
          <img src={fb} alt="fb" />
        </a>
        <a
          href="https://www.instagram.com/szymaszek.instalacje/?igshid=ZDdkNTZiNTM%3D"
          target="_blank"
          rel="noreferrer"
        >
          <img src={insta} alt="insta" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
