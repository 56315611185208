import '../styles/Navbar.scss';
import Logo from '../assets/logo.svg';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const route = useLocation();

  return (
    <div className="navbar container">
      <div className="nav">
        <div className="logo">
          <Link to={`/`}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>

        <nav className="pages">
          <Link
            className={
              route.pathname === '/' ? 'main-page active-page' : 'main-page'
            }
            to={`/`}
          >
            STRONA GŁÓWNA
          </Link>
          <Link
            className={route.pathname === '/services' ? 'active-page' : ''}
            to={`/services`}
          >
            OFERTA
          </Link>
          <a className="contact-page" href="#contact">
            KONTAKT
          </a>
        </nav>
      </div>
      <div className="nav"></div>
    </div>
  );
}

export default Navbar;
