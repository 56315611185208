import './App.scss';
import './styles/global-styles.scss';
import Layout from './layouts/Layout';

import PagesContextProvider from './context/pagesContext';

function App() {
  return (
    <div className="App">
      <PagesContextProvider>
        <Layout />
      </PagesContextProvider>
    </div>
  );
}

export default App;
