import '../styles/Services.scss';
import { services } from '../data/data';
import { PagesContext } from '../context/pagesContext';
import { useContext, useEffect } from 'react';

function Services() {
  const { whichID, setWhichID } = useContext(PagesContext);

  useEffect(() => {
    const access = document.querySelector(`#card-${whichID}`);
    if (whichID !== 0) {
      access.scrollIntoView();
      setWhichID(0);
    }
  }, []);

  return (
    <div className="services container">
      <div id="damn" className="services-inner">
        <div>
          <h1 className="services-header font-64-32">NASZA OFERTA</h1>
          <p className="services-para font-24-16">
            Indywidualne podejście i posiadana wiedza techniczna umożliwia nam
            zaspokojenie potrzeb klienta, oraz dostosowanie ich do faktycznych
            możliwości realizacyjnych. Każde zlecenie wyceniamy na podstawie
            informacji i dokumentacji uzyskanych od klienta, oraz oględzin na
            docelowym miejscu zakładanej inwestycji. Czas trwania sporządzenia
            wyceny trwa od 3-5 dni roboczych. Wycena jest bezpłatna.
          </p>
        </div>

        <div className="services-cards">
          {services.map((e) => (
            <div id={`card-${e.id}`} key={e.id} className="card">
              <hr className="card-hr" />
              <h3 className="card-h3 font-32-20">{e.titleService}</h3>
              <p className="card-p">{e.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
