import '../styles/Home.scss';
import { services } from '../data/data';
import { PagesContext } from '../context/pagesContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

function Home() {
  const { whichID, setWhichID } = useContext(PagesContext);

  const [hasIntersected, setHasIntersected] = useState(false);
  const [hasIntersectedTwo, setHasIntersectedTwo] = useState(false);

  const targetFirstRef = useRef(null);
  const targetSecondRef = useRef(null);

  const refArr = [targetFirstRef, targetSecondRef];

  const navigate = useNavigate();
  const scrollToTop = (id) => {
    navigate('/services');

    setWhichID(id);
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.target.id === 'cards-motion') {
          if (entry.isIntersecting) {
            setHasIntersected(true);
          } else {
            setHasIntersected(false);
          }
        }
        if (entry.target.id === 'about-motion') {
          if (entry.isIntersecting) {
            setHasIntersectedTwo(true);
          } else {
            setHasIntersectedTwo(false);
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback);
    refArr.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="home ">
      <section className="home-intro ">
        <div className="bg-intro"></div>
        <div className="container home-txt">
          <p className=" font-32-20">
            Szukasz profesjonalistów potrzebnych do realizacji swoich planów
            remontowych, instalacyjnych czy zaplanowanych prac modernizacyjnych?
          </p>
          <p className="font-32-20 assure">
            Zapewniam, że <b>dobrze</b> trafiłeś.
          </p>
        </div>
      </section>
      <section className="home-about ">
        <div className="bg"></div>
        <div className=" about-container">
          <h2
            className={`about-header font-64-48 ${
              hasIntersectedTwo ? 'ob-unblur' : 'ob-hide'
            }`}
          >
            O NAS
          </h2>
          <div className={'bg-about'}></div>
          <div
            id="about-motion"
            ref={targetSecondRef}
            className=" about-txt font-18-16 "
          >
            <p className={hasIntersectedTwo ? 'ob-show' : 'ob-hide left'}>
              W branży remontowo - instalacyjnej nieustannie zdobywamy
              doświadczenie od ponad 15 lat realizując liczne zlecenia. Jesteśmy
              otwarci na nowe technologie, dlatego wszelkiego rodzaju nowoczesne
              rozwiązania pozwalają na sprostanie nawet najbardziej wymagającym
              klientom. W naszej pracy nie ma miejsca na błędy i poprawki,
              pracujemy szybko i bez zarzutu. Podchodzimy indywidualnie do
              klienta wychodząc naprzeciw oczekiwaniom.
            </p>
          </div>
        </div>
      </section>
      <section className="home-services">
        <div className="container">
          <h2 className="font-46-22 services-header">
            Usługi świadczymy w zakresie:
          </h2>
          <div
            ref={targetFirstRef}
            id="cards-motion"
            className="services-cards"
          >
            {services.map((e) => (
              <div
                className="card-outter"
                key={e.id}
                onClick={() => scrollToTop(e.id)}
              >
                <div
                  className={`card card-${e.id} font-28-16 ${
                    hasIntersected ? 'ob-show' : 'ob-hide '
                  } `}
                >
                  {e.titleHome}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
