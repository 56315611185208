import '../App.scss';
import Navbar from '../components/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';

function Layout() {
  return (
    <>
      <header className="App-header">
        <Navbar />
      </header>
      <main>
        <Outlet />
      </main>
      <footer id="contact">
        <Footer />
      </footer>
    </>
  );
}

export default Layout;
